@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
.App {
  text-align: center;
}
* {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}
body,
html {
  background: #210045;
}
button {
  cursor: pointer;
}
.backdrop {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}
.backdrop .modal {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 800px;
  margin: 0 auto;
  background: #210045;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.backdrop .modal-close-button {
  position: absolute;
  top: 30px;
  left: -45px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  transform: translate(-15px, 15px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;
}
@media (max-width: 800px) {
  .backdrop .modal-close-button {
    top: 10px;
    left: 20px;
    width: 50px;
    height: 50px;
  }
}
.backdrop .modal-close-button svg {
  width: 20px;
  height: 20px;
  fill: #271ac1;
}
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}
*::-webkit-scrollbar-track {
}
